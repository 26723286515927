import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import Disqus from 'disqus-react';



class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const disqusShortname = 'elijahlynn';
    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title,
    };

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
          <article>
              <h1>{post.frontmatter.title}</h1>

              <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(.3),
                marginTop: rhythm(-1),
              }}
            >
              {post.frontmatter.date}
              &nbsp; | &nbsp;
                  <Disqus.CommentCount shortname={disqusShortname} config={disqusConfig}>
                      Comments
                  </Disqus.CommentCount>
              </p>
            <hr />
              <div dangerouslySetInnerHTML={{ __html: post.html }} />

              <div>Tags:
              {post.frontmatter.tags.map(tag => (
                  <span>&nbsp;
                       <Link to={`/blog/tags/${tag}/`}>
                          {tag}
                      </Link>
                  </span>
              ))}
              </div>
              <br />
              <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />

              <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
                <li>
                  {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
                <li>
                  {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
              <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
